import { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../context';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Input,
    InputLabel,
    LinearProgress,
    TextField,
    Tooltip,
} from '@mui/material';
import { api } from '../../services';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import {
    DataGrid,
    GridColDef,
    GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CachedIcon from '@mui/icons-material/Cached';
import Moment from 'react-moment';
import { IAdHocInvite, IUserInfo } from '../../types';
import jstz from 'jstz';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
    InputContainer,
    LidapCard,
    LidapCardDisabled,
    ElevateCard,
    ElevateCardDisabled,
    NoDataCircle,
    OptionsButton,
} from '../../styled';
import { IAutocompleat, IUser, IBodyPassChange } from '../../types';
import { AppIdEnum } from '../../types/app';

const defaultValues = {
    FirstName: '',
    LastName: '',
    Email: '',
    Location: '',
    Supervisor: '',
    PhoneNumber: '',
    PositionTitle: '',
    Company: '',
    Language: 'en-US',
};

const UserActivity = () => {
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [adminsUsers, setAdminsUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const { t } = useTranslation();

    const [activities, setActivities] = useState([]);
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [usersEntitlements, setUsersEntitlements] = useState<any[]>([]);
    const [userObject, setUserObject] = useState<IBodyPassChange>();

    const [formValues, setFormValues] = useState<IAdHocInvite>(defaultValues);
    const [users, setUsers] = useState<IAutocompleat[]>([]);

    const userInput = useRef<HTMLElement>(null);
    const supervisorsInput = useRef<HTMLElement>(null);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
    const [usersInputValue, setUsersInputValue] = useState<string>('');
    const [bodyPassChange, setBodyPassChange] = useState<IBodyPassChange | any>();
    const [usersData, setUsersData] = useState([]);

    const timezone = jstz.determine();
    const timezoneName = timezone.name();

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                <GridToolbarFilterButton
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                <GridToolbarDensitySelector
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'timeStamp',
            headerName: t('formField.createdAt'),
            width: 200,
            resizable: true,
            renderCell: (params: any) => (
                <Moment format="YYYY/MM/DD HH:mm">
                    {params.formattedValue.toLocaleString(timezoneName)}
                </Moment>
            ),
            type: 'date',
        },
        {
            field: 'message',
            headerName: t('label.Message'),
            width: 900,
            renderCell: (params: any) => (
                <Tooltip title={params.formattedValue}>
                    <span
                        className="table-cell-trucate"
                        style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                    >
                        {params.formattedValue}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const customGridProps = {
        filterPanel: {
            linkOperators: [GridLinkOperator.And],
            columnsSort: 'asc',
            filterFormProps: {
                linkOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                },
                columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' },
                },
                operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' },
                },
                valueInputProps: {
                    InputComponentProps: {
                        variant: 'outlined',
                        size: 'small',
                    },
                },
                deleteIconProps: {
                    sx: {
                        '& .MuiSvgIcon-root': { color: '#d32f2f' },
                    },
                },
            },
            sx: {
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 5 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 5, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 5 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 },
            },
        },
    };

    const options: { label: string | Element | any; value: string }[] = [];

    state.user &&
        state.user?.firstName &&
        state.user?.lastName &&
        adminsUsers.forEach((user: any) => {
            options.push({
                value: user.userObjectId,
                label: user.firstName + ' ' + user.lastName + ' ' + user.email,
            });
        });

    useEffect(() => {
        if (userObject && userObject.email === null) {
            toast.info('User has no email, please choose another user');
        } else
            userObject &&
                userObject.id !== undefined &&
                api.userDetails
                    .getUserActionsById(
                        state.user,
                        generateToken(instance, accounts),
                        userObject.id,
                    )
                    .then((res: any) => {
                        setActivities(res.userActions);
                        setUserInfo(res.user);
                        setBodyPassChange({
                            firstName: res.user.firstName,
                            lastName: res.user.lastName,
                            email: res.user.email,
                            userObjectId: res.user.userObjectId,
                        });
                        setUsersEntitlements(res.entitlements);
                    });
    }, [userObject]);

    const getSupervisors = () => {
        setIsUsersLoading(true);
        api.userDetails
            .GetUsersInBusinessSegments(
                state.user,
                generateToken(instance, accounts),
                state.chosenBusinessSegment.internalId,
                state.appId,
                usersInputValue,
            )
            ?.then((res: IUser[]) => {
                if (!res) return;
                setIsUsersLoading(false);
                setUsers(
                    res.map((supervisor) => ({
                        id: supervisor.userObjectId,
                        label: `${supervisor.firstName}  ${supervisor.lastName}  -  (${supervisor.email})`,
                        email: supervisor.email,
                    })),
                );
                userInput.current?.focus();
            });
    };

    let timeoutId: any = null;
    const handleUsersChange = (event: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            if (event.target.value !== undefined && event.target.value.length > 3) {
                setIsLoading(true);
                api.userDetails
                    .GetUsersInBusinessSegments(
                        state.user,
                        generateToken(instance, accounts),
                        state.businessSegment,
                        state.appId,
                        event.target.value,
                    )
                    ?.then((res: any) => {
                        if (!res) return;
                        setIsLoading(false);
                        setUsersData(
                            res.map((supervisor: any) => ({
                                id: supervisor.id,
                                label: `${supervisor.firstName}  ${supervisor.lastName}  -  (${supervisor.email})`,
                                email: supervisor.email,
                            })),
                        );
                        supervisorsInput.current?.focus();
                    });
            }
        }, 300);
    };

    const handlePassChange = () => {
        setResetLoading(true);
        bodyPassChange &&
            api.userDetails
                .changePassword(
                    state.user,
                    generateToken(instance, accounts),
                    state.appId,
                    bodyPassChange,
                )
                ?.then((res: any) => {
                    setResetLoading(false);
                    const message = i18n.t('successMessage.passChanged');
                    toast.success(message);
                });
    };

    const removeEntitlement = (entitlementId: number) => {
        setRemoveLoading(true);
        api.userDetails
            .removeEntitlement(
                state.user,
                generateToken(instance, accounts),
                bodyPassChange.userObjectId,
                entitlementId,
            )
            ?.then((res: any) => {
                /*            if (!res) {
                setRemoveLoading(false)
                toast.error('Error')
                return;
            }*/
                if (res) {
                    const message = i18n.t('successMessage.entitlementRemoved');
                    toast.success(message);
                    userObject &&
                        userObject.id &&
                        api.userDetails
                            .getUserActionsById(
                                state.user,
                                generateToken(instance, accounts),
                                userObject.id,
                            )
                            .then((res: any) => {
                                setUsersEntitlements(res.entitlements);
                                setActivities(res.userActions);
                                setRemoveLoading(false);
                            });
                } else {
                    setRemoveLoading(false);
                    toast.error('Failed to remove entitlement');
                }
            });
    };

    return (
        <>
            <Grid container columnSpacing={12} sx={{ marginTop: '15px' }}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    {state.businessSegment === undefined && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                left: 48,
                                right: 0,
                                bottom: 27,
                            }}
                        />
                    )}
                    {isLoading && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                left: 48,
                                right: 0,
                                bottom: 27,
                            }}
                        />
                    )}
                    <InputContainer>
                        <InputLabel htmlFor="location">{t('label.SearchUser')}</InputLabel>
                        <Autocomplete
                            openOnFocus
                            disablePortal
                            id="combo-box-demo"
                            options={usersData}
                            freeSolo={true}
                            onInputChange={(event) => handleUsersChange(event)}
                            onChange={(event: any, newValue: string | null | any) =>
                                newValue && setUserObject(newValue)
                            }
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} inputRef={userInput} variant="standard" />
                            )}
                        />
                        <p
                            style={{
                                margin: 0,
                                fontSize: '11px',
                                color: 'gray',
                            }}
                        >
                            {t('label.threeOrMore')}
                        </p>
                    </InputContainer>
                </Grid>
                {usersInputValue.length > 2 && (
                    <IconButton onClick={getSupervisors}>
                        <CachedIcon />
                    </IconButton>
                )}
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    {isUsersLoading && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 10,
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            {userInfo !== undefined && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            backgroundColor: '#f6fcff',
                            borderRadius: '12px',
                            padding: '20px',
                            marginTop: '30px',
                        }}
                    >
                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-name">
                                        {t('formField.firstName')}
                                    </InputLabel>
                                    <Input
                                        disabled
                                        fullWidth
                                        id="user-name"
                                        name="FirstName"
                                        value={userInfo.firstName}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={6}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-last-name">
                                        {t('formField.lastName')}
                                    </InputLabel>
                                    <Input
                                        disabled
                                        fullWidth
                                        id="user-last-name"
                                        name="LastName"
                                        value={userInfo.lastName}
                                    />
                                </InputContainer>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-email">
                                        {t('formField.email')}
                                    </InputLabel>
                                    <Input
                                        disabled
                                        fullWidth
                                        id="user-email"
                                        name="Email"
                                        value={userInfo.email}
                                    />
                                </InputContainer>
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-email">
                                        {t('formField.telephone')}
                                    </InputLabel>
                                    <Input
                                        disabled
                                        fullWidth
                                        id="user-email"
                                        name="workPhone"
                                        value={userInfo.workPhone}
                                    />
                                </InputContainer>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="b2cObjectId">
                                        {t('formField.b2cObjectId')}
                                    </InputLabel>
                                    <Input
                                        disabled
                                        fullWidth
                                        id="b2cObjectId"
                                        name="FirstName"
                                        value={userInfo.userObjectId}
                                    />
                                </InputContainer>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                marginBottom: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '30px',
                            }}
                        >
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                {/*<Button startIcon={<SaveIcon />} type="submit" variant="contained">{"Update User's info"}</Button>*/}
                            </Grid>
                            <Grid item xs={6}>
                                {resetLoading === true ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" onClick={handlePassChange}>
                                        {t('label.PassResetButton')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <div style={{ display: 'flex' }}>
                        <Box
                            sx={{
                                height: 'calc(100vh - 230px)',
                                width: '80%',
                                backgroundColor: '#f6fcff',
                                borderRadius: '12px',
                                padding: '10px',
                                marginTop: '30px',
                            }}
                        >
                            {activities && activities.length > 0 && activities !== null ? (
                                <>
                                    <p>{t('label.Activity')}</p>
                                    <DataGrid
                                        rows={activities}
                                        columns={columns}
                                        getRowHeight={() => 'auto'}
                                        pageSize={50}
                                        rowsPerPageOptions={[10]}
                                        loading={isLoading}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        componentsProps={customGridProps}
                                        sx={{
                                            borderStyle: 'none',
                                            '& .MuiDataGrid-row': {
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                            },
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <p>{t('label.noActivity')}</p>
                                    <NoDataCircle>
                                        <ManageSearchOutlinedIcon
                                            color="disabled"
                                            fontSize="inherit"
                                        />
                                    </NoDataCircle>
                                </>
                            )}
                        </Box>
                        {removeLoading ? (
                            <CircularProgress />
                        ) : (
                            <div
                                style={{
                                    position: 'relative',
                                    marginBottom: 3,
                                    marginTop: '30px',
                                    marginLeft: '30px',
                                    backgroundColor: '#f6fcff',
                                    height: 'calc(100vh - 230px)',
                                    borderRadius: '12px',
                                    padding: '10px',
                                    width: '20%',
                                }}
                            >
                                <p>{t('label.ManageUsersApps')}</p>
                                <Grid container columnSpacing={2} sx={{}}>
                                    {usersEntitlements.map((data: any) => {
                                        if (data.isEntitled) {
                                            if (data.appInfoId === AppIdEnum.LIDAP) {
                                                return (
                                                    <LidapCard>
                                                        <OptionsButton
                                                            onClick={() => {
                                                                removeEntitlement(data.id);
                                                            }}
                                                        >
                                                            {' '}
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    color: 'red',
                                                                }}
                                                            />{' '}
                                                        </OptionsButton>
                                                    </LidapCard>
                                                );
                                            } else if (data.appInfoId === 1) {
                                                return (
                                                    <ElevateCard>
                                                        <OptionsButton
                                                            onClick={() => {
                                                                removeEntitlement(data.id);
                                                            }}
                                                        >
                                                            {' '}
                                                            <RemoveCircleIcon
                                                                sx={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    color: 'red',
                                                                }}
                                                            />{' '}
                                                        </OptionsButton>
                                                    </ElevateCard>
                                                );
                                            }
                                        }
                                    })}
                                </Grid>
                                <Grid container columnSpacing={2} sx={{ marginTop: '30px' }}>
                                    {usersEntitlements.map((data: any) => {
                                        if (!data.isEntitled) {
                                            if (data.appInfoId === 3) {
                                                return <LidapCardDisabled></LidapCardDisabled>;
                                            } else if (data.appInfoId === 1) {
                                                return <ElevateCardDisabled></ElevateCardDisabled>;
                                            }
                                        }
                                    })}
                                </Grid>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default UserActivity;
