import { UserDetailedInfo } from '../../../types/user';
import { Nullable } from '../../../types/util';

export function findPathToId(node: OrgUnit, targetId: number): number[] | undefined {
    // If the current node is the target, return it as the start of the path
    if (node.data.internalId === targetId) {
        return [node.data.internalId];
    }

    // Recursively search through children nodes
    for (const child of node.children) {
        const path = findPathToId(child, targetId);
        if (path) {
            return [node.data.internalId, ...path]; // Add current node's ID to the path
        }
    }

    return undefined; // Return null if the target ID is not found in this path
}

interface CurrentLevel {
    level: 1 | 2 | 3 | 4 | 5;
    type: 'internal';
    id?: number | string;
}

export function findLargestLevelWithId(data: Partial<UserDetailedInfo>): Nullable<CurrentLevel> {
    for (let level = 5 as const; level >= 1; level--) {
        const internalId = data[`orgUnitLevel${level}InternalId`];

        if (internalId !== null) {
            return { level, type: 'internal', id: internalId };
        }
    }

    return null; // If no level has an ID
}

export const findOrgUnit = (orgUnits: OrgUnit[], id: number | string): Nullable<OrgUnit> => {
    for (const orgUnit of orgUnits) {
        if (orgUnit.data.internalId === id) {
            return orgUnit;
        }
        if (orgUnit.children) {
            const found = findOrgUnit(orgUnit.children, id);
            if (found) return found;
        }
    }
    return null;
};

export function populateOrgUnitLevels(
    path: number[] | null,
    maxLevel: number = 5,
): Partial<UserDetailedInfo> {
    // Reduce to populate the levels based on the path
    const orgLevels: Partial<UserDetailedInfo> = (path || []).reduce((prev, id, index) => {
        if (index + 1 > maxLevel) {
            return prev;
        }
        return {
            ...prev,
            [`orgUnitLevel${index + 1}InternalId`]: id,
        };
    }, {});

    // Set remaining levels to null
    for (let level = (path || []).length + 1; level <= maxLevel; level++) {
        orgLevels[`orgUnitLevel${level}InternalId` as keyof Partial<UserDetailedInfo>] = null;
    }

    return orgLevels;
}
